.heading_about{
    text-align: center;
    color: var(--brown);
    text-transform: uppercase;
    font-size: 3rem;
    margin-top: 2rem;
}

.about_underline{
    margin-top: -5rem;
    text-align: center;
    color: var(--gold);
    font-size: 7rem;
    font-weight: lighter;
}

.heading_about span{
    color: var(--gold);
}

.about .row{
    display:flex;
    width: 100%;
    align-items: center;
    background-color: var(-white);
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -4rem;
}

.about .row .image{
    flex: 1 1 20rem;
    padding: 2rem;
}

.about .row .image img{
    width: 100%;
}

.about .row .content{
    flex: 3 1 30rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
}
.about .row .content p{
    font-size: 1rem;
    color:var(--black);
    line-height: 1.5rem;
    text-align: justify;
    text-justify: justify;
}
@media screen and (min-width: 1100px) {

    .about .row .content h3{
        font-size: 2.5rem;
        color: var(--brown);
    }
    .carousel{
        height: 25rem;
        width: 30rem;
    }
}

@media screen and (max-width: 1100px) {

    .about .row .content h3{
        font-size: 2rem;
        color: var(--brown);
    }
    .carousel{
        height: 20rem;
        width: 25rem;
    }

}

@media screen and (max-width: 700px) {

    .about .row .content h3{
        font-size: 1.5rem;
        font-weight: bolder;
        color: var(--brown);
    }
}

@media screen and (max-width: 400px) {

    .about .row .content h3{
        font-size: 1rem;
        font-weight: bolder;
        color: var(--brown);
    }
}

@media screen and (max-width: 600px) {
    .carousel{
        height: 10rem;
        width: 20rem;
    }
}

