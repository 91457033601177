.scrollButton{
    z-index: 2000;
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    transform: rotate(225deg);
    cursor: pointer;
}

.scrollButton i {
    font-size: 4rem;
    font-weight: bolder;
    color: var(--gold);
    padding-bottom: .7rem;
}