*{
    font-family: 'Ubuntu', Times, serif, sans-serif;
    margin-top: 0; 
    padding-top: 0; 
    margin-bottom: 0; 
    padding-bottom: 0;
}



html {
    scroll-behavior: smooth;
  }

:root {
    --yellow: #fcb017;
    --gold-orange: #d56040;
    --black: #000000;
    --blue-white: #F5FBFD;
    --dark-brown: #331118;
    --light-brown: #3f1925;
    --brown: #452A24;
    --white: #ffffff;
    --gold: #FFBA6A;
}


body{
    background: #ffffff;
}

.App{
    min-height: calc(100vh - 150px - 72px);
}


@media screen and (max-width: 600px){
    .flexRow{
        display: block;
    }
}
@media screen and (min-width: 600px){
    .flexRow{
        display: flex;
    }
}

.sb{
    justify-content: space-between;
}
