  #contact {
      min-height: 80vh;
      width: 100%;
      background: url(./contact_back.jpeg) no-repeat;
      background-size: cover;
      background-position: center;
      margin-bottom: 2rem;
      margin-top: -2rem;
  }

  .heading h1 {
      font-size: 3rem;
      color: var(--brown);
  }

  .heading h1 span {
      color: var(--gold);
  }

  .contact_underline {
      margin-top: -5rem;
      text-align: center;
      color: var(--gold);
      font-size: 7rem;
      font-weight: lighter;
  }

  #contact form {
      background: var(--blue-white);
      padding-top: 1rem;
      margin: 1rem 2rem;
      border-radius: 1rem;
      -webkit-box-shadow: 0 0 2rem var(--brown);
      box-shadow: 0 0 2rem var(--brown);
  }

  #contact form .inputBox {
      position: relative;
      text-align: center;
  }

  #contact form .inputBox input,
  #contact form .inputBox textarea {
      outline: none;
      border: .2rem solid var(--brown);
      border-radius: .2rem;
      margin: 2rem 0;
      padding: 0 1rem;
      background: none;
      height: 3rem;
      width: 90%;
      font-size: 1rem;
  }

  #contact form .inputBox input:focus~h3,
  #contact form .inputBox input:valid~h3,
  #contact form .inputBox textarea:focus~h3,
  #contact form .inputBox textarea:valid~h3 {
      font-size: 1.2rem;
      left: 6%;
      top: -.5rem;
      color: var(--gold);
      display: block;
  }

  #contact form .inputBox textarea {
      padding: 1rem;
      resize: none;
      height: 10rem;
  }

  #contact form .inputBox h3 {
      position: absolute;
      top: 2.5rem;
      left: 7%;
      display: none;
  }

  #contact form input[type="submit"] {
      outline: none;
      border: none;
      border-radius: 5rem;
      color: var(--brown);
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: capitalize;
      letter-spacing: .2rem;
      cursor: pointer;
      position: relative;
      z-index: 1;
      overflow: hidden;
      height: 3rem;
      width: 12rem;
      margin-bottom: 1rem;
      margin-left: 4%;
      background: var(--brown);
      color: var(--blue-white);
  }

  #contact form input[type="submit"]::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: var(--gold);
      height: 100%;
      width: 0%;
      -webkit-transition: .2s;
      transition: .2s;
      z-index: -1;
  }

  #contact form input[type="submit"]:hover::before {
      width: 100%;
  }

  #contact form input[type="submit"]:hover {
      color: var(--brown);
      background: var(--gold);
  }

  #contact form input[type="submit"]:hover:disabled{
    color: var(--white);
    background: var(--brown);
    cursor: not-allowed;
}


  .recaptcha {
      margin-bottom: 1rem;
  }

  @media only screen and (min-width : 480px) {
      .recaptcha {
          margin-left: 6%;
      }
  }
